window.klaroConfig = {
  privacyPolicy: '/datenschutz',
  acceptAll: true,
  translations: {
    de: {
      consentNotice: {
        description: 'Diese Seite nutzt Website Tracking-Technologien von Dritten, um ihre Dienste anzubieten, stetig zu verbessern und Werbung entsprechend der Interessen der Nutzer anzuzeigen. Ich bin damit einverstanden und kann meine Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen oder ändern.',
      },
      purposes: {
        analytics: 'Besucherstatistiken',
        embeds: 'Inhalte von Drittanbietern',
      },
      googleAnalytics: {
        title: 'Google Analytics',
        description: 'Sammeln von Besucherstatistiken',
      },
      instagram: {
        title: 'Instagram',
        description: 'Einbindung von Instagram-Posts',
      },
      youTube: {
        title: 'YouTube',
        description: 'Einbindung von Videos',
      },
      acceptSelected: 'Nur ausgewählten zustimmen',
    },
  },
  services: [{
    name: 'googleAnalytics',
    purposes: ['analytics'],
    cookies: [
      [/^_g/, '/', '.brueckensteine.de'],
    ],
  }, {
    name: 'instagram',
    purposes: ['embeds'],
  }, {
    name: 'youTube',
    purposes: ['embeds'],
  }],
};
